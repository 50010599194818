<template>
  <div class="your-info mt-4 mt-lg-0">
    <h1 class="title">{{$t('Your Info')}}</h1>
    <ValidationObserver ref="validator">
      <form class="your-info-form" @submit.prevent="update">
        <div class="info">
          <RFValidation :name="$t('Firstname')" rules="required">
            <RFInput :label="$t('Firstname')" v-model="userInfos.firstname" :value="userInfos.firstname" :readonly="isVerifiedStatus"/>
          </RFValidation>

          <RFValidation :name="$t('Lastname')" rules="required">
            <RFInput :label="$t('Lastname')" v-model="userInfos.lastname" :value="userInfos.lastname" :readonly="isVerifiedStatus"/>
          </RFValidation>

          <RFValidation :name="$t('Birth Date')" rules="required">
            <RFDatePicker :label="$t('Birth Date')" v-model="userInfos.birthDate" :value="userInfos.birthDate" :readonly="isVerifiedStatus"/>
          </RFValidation>

          <RFValidation :name="$t('Gender')" rules="required">
            <RFRadio class="ml-3" :label="$t('Gender')" name="gender" @input="userInfos.genre = $event" :value="userInfos.genre" :list="[{ label: 'M', value: 'MALE' }, { label: 'F', value: 'FEMALE' }]" :disabled="isVerifiedStatus"/>
          </RFValidation>

          <RFValidation :name="$t('Nationality')" rules="required">
            <RFInputAutocomplete :label="$t('Nationality')" :value="userInfos.nationality" :items="nationalities" v-model="userInfos.nationality" :disabled="isVerifiedStatus"/>
          </RFValidation>

          <RFValidation :name="$t('Email')" rules="required|email">
            <RFInput :label="$t('Email')" v-model="userInfos.email" :value="userInfos.email" :readonly="true"/>
          </RFValidation>

          <RFValidation :name="$t('Country Code')" rules="required">
            <RFInputAutocomplete :label="$t('Country Code')" :value="userInfos.countryCode" :items="countryCodes" v-model="userInfos.countryCode" :disabled="isVerifiedStatus"/>
          </RFValidation>

          <RFValidation name="Whatsapp Contact" rules="required|phoneNumber">
            <RFInput label="Whatsapp contact" v-model="userInfos.whatsappContact" :value="userInfos.whatsappContact" is-numeric :readonly="isVerifiedStatus"/>
          </RFValidation>

          <RFRadio class="ml-3" label="What do you do?" name="employment" v-model="userInfos.employment" :list="[{ label: 'Study', value: 'study'}, { label: 'Work', value: 'work' }]" :disabled="isVerifiedStatus"/>

          <RFRadio class="ml-3" label="Do you have a University Letter?" name="uni-letter" v-model="uniLetter" :list="[{ label: 'Yes', value: 'yes'}, { label: 'No', value: 'no' }, { label: 'Not Yet', value: 'not_yet'}]"/>

          <div class="your-info-subtitle">{{$t('Complete your personal info')}}</div>

          <RFValidation :name="$t('Fiscal Code')" rules="required|fiscalCode" v-if="isItalian">
            <RFInput :label="$t('Fiscal Code')" v-model="userInfos.fiscalCode" :value="userInfos.fiscalCode" :readonly="isVerifiedStatus" uppercase/>
          </RFValidation>

          <RFValidation :name="$t('Fiscal Code')" rules="fiscalCode" v-else>
            <RFInput :label="$t('Fiscal Code')" v-model="userInfos.fiscalCode" :value="userInfos.fiscalCode" :readonly="isVerifiedStatus" uppercase
            description="Alphanumeric code issued by the Italian Government (Agenzia delle Entrate) for your identification. It’s only necessary to register your contract. If you don’t have it, we can do it for you. Or add it later"/>
          </RFValidation>

          <RFValidation :name="isItalian ? 'ID Document #' : 'Passport #'" rules="required">
            <RFInput :label="isItalian ? 'ID Document #' : 'Passport #'" v-model="userInfos.docNumber" :value="userInfos.docNumber" :readonly="isVerifiedStatus" uppercase/>
          </RFValidation>

          <RFValidation :name="$t('Place of birth')" rules="required">
            <RFInput :label="$t('Place of birth')" v-model="userInfos.placeOfBirth" :value="userInfos.placeOfBirth" :readonly="isVerifiedStatus"/>
          </RFValidation>

          <RFValidation :name="$t('Country')" rules="required">
            <RFInput :label="$t('Country')" v-model="userInfos.addressCountry" :value="userInfos.addressCountry" :readonly="isVerifiedStatus" v-if="isVerifiedStatus"/>
            <RFInputAutocomplete :label="$t('Country')" :value="userInfos.addressCountry" :items="countries" v-model="userInfos.addressCountry" v-else/>
          </RFValidation>

          <b-row>
            <b-col>
              <RFValidation :name="$t('City of residence')" rules="required">
                <RFInput :label="$t('City of residence')" v-model="userInfos.addressCity" :value="userInfos.addressCity" :readonly="isVerifiedStatus"/>
              </RFValidation>
            </b-col>
            <b-col cols="12" md="4">
              <RFInput :label="$t('Zip Code')" v-model="userInfos.addressZipCode" :value="userInfos.addressZipCode" is-numeric :readonly="isVerifiedStatus"/>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <RFValidation :name="$t('Street of residence')" rules="required">
                <RFInput :label="$t('Street of residence')" v-model="userInfos.addressStreet" :value="userInfos.addressStreet" :readonly="isVerifiedStatus"/>
              </RFValidation>
            </b-col>
            <b-col cols="12" md="4">
              <RFInput label="Street number" v-model="userInfos.addressNumber" :value="userInfos.addressNumber" is-numeric :readonly="isVerifiedStatus"/>
            </b-col>
          </b-row>

          <RFButton class="mt-4" label="Save" background="#fff" color="#24425B" v-if="!isVerifiedStatus && !loading"/>
          <RFLoader class="mt-4" :size="30" color="#24425B" v-if="loading"/>
        </div>
      </form>
    </ValidationObserver>
    <div class="complete-profile" v-if="isRegisteredStatus">{{$t('Please complete your info and then upload your documents.')}}</div>
    <form class="documents" @submit.prevent v-else>
      <b-row>
        <b-col>
          <div class="documents-title">{{$t('Upload your documents')}}</div>
        </b-col>
      </b-row>
      <b-row class="upload-row justify-content-between">
        <b-col cols="12">
          <span class="upload-label" v-if="isEuropean">{{$t('ID Document')}}</span>
          <span class="upload-label" v-else>{{$t('Passport')}}</span>
          <span class="upload-label">
            <p class="sub-paragraph">{{$t('It is the only mandatory document in order to complete your booking request.')}}</p>
          </span>
        </b-col>
        <b-col cols="12">
          <RFUploadDocument :docName="isEuropean ? $t('ID Document') : $t('Passport')" is-mandatory
            :disableModify="disableModify"
            :doc-type="primaryUserID" :doc-side="docSides.FULL"
            :documents="documents" :user-documents="userDocuments"
            :is-verified-status="isVerifiedStatus"
            @upload="uploadDocument($event, primaryUserID, docSides.FULL)"
          />
        </b-col>
      </b-row>
      <b-row class="upload-row">
        <b-col cols="12">
          <span class="upload-label">
            {{$t('University letter')}}
            <p class="sub-paragraph">{{$t('Acceptance letter issued by the University which shows the starting and end-date of your studies')}}</p>
          </span>
        </b-col>
        <b-col cols="12">
          <RFUploadDocument
            :docName="$t('University letter')"
            :disableModify="disableModify"
            :doc-type="docTypes.UNIVERSITY"
            :doc-side="docSides.FULL"
            :documents="documents"
            :user-documents="userDocuments"
            :is-verified-status="isVerifiedStatus"
            @upload="uploadDocument($event, docTypes.UNIVERSITY, docSides.FULL)"
          />
        </b-col>
      </b-row>
      <b-row class="upload-row no-border">
        <b-col cols="12">
          <span class="upload-label">
            {{$t('Fiscal Code')}}
            <p class="sub-paragraph">{{$t('Alphanumeric code issued by the Italian Government (Agenzia delle Entrate) for your identification. It’s only necessary to register your contract. If you don’t have it, we can do it for you. Or add it later')}}</p>
          </span>
        </b-col>
        <b-col cols="12">
          <RFUploadDocument :docName="$t('Fiscal Code')"
            :disableModify="disableModify"
            :doc-type="docTypes.FISCALCODE" :doc-side="docSides.FULL"
            :documents="documents" :user-documents="userDocuments"
            :is-verified-status="isVerifiedStatus"
            @upload="uploadDocument($event, docTypes.FISCALCODE, docSides.FULL)"
          />
        </b-col>
      </b-row>
      <b-row class="justify-content-between align-items-center mt-4">
        <b-col col class="d-flex justify-content-end ls-1">
          <div class="d-inline contact-us rf-white-color">{{`${$t('If you have any doubts')},`}}</div>
          <a href="https://wa.me/393518897372" target="_blank" class="d-inline contact-us rf-dark-gray-color ml-1">
            {{$t('Contact Us')}}
            <FontAwesomeIcon :icon="['fab', 'whatsapp']" color="#74776D"/>
          </a>
        </b-col>
      </b-row>
    </form>
    <DocumentModal />
  </div>
</template>

<script>
import RFInput from '@/components/forms/RFInput'
import RFRadio from '@/components/forms/RFRadio'
import RFDatePicker from '@/components/forms/RFDatePicker'
import RFButton from '@/components/forms/RFButton'
import RFFile from '@/components/forms/RFFile'
import RFInputAutocomplete from '@/components/forms/RFInputAutocomplete'
import RFLoader from '@/components/forms/RFLoader'
import RFValidation from '@/components/forms/RFValidation'
import RFUploadDocument from '@/components/forms/RFUploadDocument'
import RFCheckbox from '@/components/forms/RFCheckbox'

import DocumentModal from '@/components/modals/DocumentModal'

import { documentService, userService } from '@/services'
import { docSides, docTypes, nationalityTypes, userStatus } from '@/enums'
import { mapState } from 'vuex'

export default {
  name: 'ProfileYourInfo',
  components: {
    RFInput,
    RFRadio,
    RFDatePicker,
    RFButton,
    RFFile,
    RFInputAutocomplete,
    RFLoader,
    RFValidation,
    RFUploadDocument,
    RFCheckbox,
    DocumentModal,
  },
  data() {
    return {
      docTypes,
      docSides,
      userStatus,

      disableModify: false,

      userInfos: null,

      documents: [],
      uniLetter: null,
      loading: false,
      hasFrontBackDocument: false,
      hasFrontBackFiscalCode: false,
      fileSizeLimit: 100, //In MB
    }
  },
  computed: {
    ...mapState({
      document: state => state.documentStore.document,
      user: state => state.userStore.user,
      storedCountries: state => state.countryStore.countries,
    }),
    isVerifiedStatus() {
      return this.user.status === this.userStatus.VERIFIED
    },
    isRegisteredStatus() {
      return this.user.status === this.userStatus.REGISTERED
    },
    isEuropean() {
      return this.user.ueStatus === nationalityTypes.UE || this.user.ueStatus === nationalityTypes.ITALIAN
    },
    primaryUserID() {
      return this.isEuropean ? docTypes.ID_DOCUMENT : docTypes.PASSPORT
    },
    userDocuments() {
      return this.user.documents
    },
    nationalities() {
      return this.storedCountries.map(country => country.nationality)
    },
    countryCodes() {
      // return this.storedCountries.map(country => country.name + ' - ' + country.dial_code)
      return this.storedCountries.map(country => country.name)
    },
    countries() {
      return this.storedCountries.map(country => country.name)
    },
    isItalian() {
      return this.user.nationality ? this.user.nationality.toLowerCase() === 'italian' : ''
    }
  },
  mounted() {
    if (this.checkAllDocuments()) {
      this.disableModify = true
    } else {
      this.disableModify = false
    }
  },
  beforeMount() {
    if (this.checkAllDocuments) {
      this.disableModify = true
    }
    this.userInfos = { ...this.user }

    if (this.userInfos.nationality) {
      const index = this.storedCountries.findIndex(country => country.nationality === this.userInfos.nationality)
      this.userInfos.addressCountry = this.storedCountries[index].name
    }
  },
  methods: {
    async update() {
      const isValid = await this.$refs.validator.validate()

      if (isValid) {
        this.loading = true
        const updatedUser = await userService.updateUser(this.user.id, this.userInfos)

        this.$nextTick(() => {
          this.userInfos = { ...updatedUser }
          this.$store.commit('userStore/setUser', updatedUser)
          this.$toasted.success(this.$i18n.t(`User data successfully updated!`))
          this.loading = false
        })
      }
    },
    isAlreadyUploaded(docType, docSide, warning = false) {
      if (warning) {
        console.log('isAlreadyUploaded =>', docType, docSide, !!this.userDocuments.find(document => document.docType === docType && document.docSide === docSide))
      }
      return !!this.userInfos.documents.find(document => document.docType === docType && document.docSide === docSide)
    },
    isInDocuments(docType, docSide, warning = false) {
      if (warning) {
        console.log('isInDocuments =>', docType, docSide, !!this.documents.find(document => document.docType === docType && document.docSide === docSide))
      }
      return !!this.documents.find(document => document.docType === docType && document.docSide === docSide)
    },
    checkAllDocuments() {
      if (( this.isAlreadyUploaded(this.primaryUserID, this.docSides.FULL) ||
          (this.isAlreadyUploaded(this.primaryUserID, this.docSides.FRONT) && this.isAlreadyUploaded(this.primaryUserID, this.docSides.BACK)))
          && this.isAlreadyUploaded(this.docTypes.UNIVERSITY, this.docSides.FULL) &&
          ( this.isAlreadyUploaded(this.docTypes.FISCALCODE, this.docSides.FULL) ||
          (this.isAlreadyUploaded(this.docTypes.FISCALCODE, this.docSides.FRONT) && this.isAlreadyUploaded(this.docTypes.FISCALCODE, this.docSides.BACK)))) {
        return true
      } else {
        return false
      }
    },
    getDocumentNameToSave(docType,userId,format){
      switch (docType) {
        case docTypes.PASSPORT:
          return "passport_{0}.{1}".replace("{0}",userId).replace("{1}",format)
        case docTypes.ID_DOCUMENT:
          return "id_doc_{0}.{1}".replace("{0}",userId).replace("{1}",format)
        case docTypes.UNIVERSITY:
          return "uni_letter_{0}.{1}".replace("{0}",userId).replace("{1}",format)
        case docTypes.FISCALCODE:
          return "fiscal_code_{0}.{1}".replace("{0}",userId).replace("{1}",format)
      }
    },
    uploadDocument(document, docType, docSide) {
      this.$nextTick(() => {
        if (document.size <= this.fileSizeLimit * 1024 * 1024) {
          this.isInDocuments(docType, docSide)
          this.documents.push({
            document,
            docSide,
            docType,
          })
          this.sendDocument(docType, docSide)
        } else {
          this.$toasted.error(this.$i18n.t('File size too bigger. Try again with another smaller file.'))
        }
      })
    },
    showDocument(docType, docSide) {
      const index = this.documents.findIndex(document => document.docType === docType && document.docSide === docSide)
      const document = this.documents[index]

      this.$store.commit('documentStore/setDocument', document)
      this.$root.$emit('bv::show::modal', 'document-modal')
    },
    async sendDocument(docType, docSide) {
      const formData = new FormData()

      const index = this.documents.findIndex(document => document.docType === docType && document.docSide === docSide)
      const document = this.documents[index]

      formData.append('file', document.document)
      formData.append('docSide', document.docSide)
      formData.append('docType', document.docType)

      const { data: updatedUser, status } = await documentService.sendDocument(formData, this.user.id)

      if (status === 200) {
        this.$nextTick(() => {
          this.userInfos = { ...updatedUser }
          this.$store.commit('userStore/setUser', updatedUser)
          this.documents.splice(index, 1)

          if (docType === this.primaryUserID && (!this.hasFrontBackDocument && docSide === this.docSides.FULL || docSide === this.docSides.FRONT || docSide === this.docSides.BACK)) {
            if (this.userInfos.status === this.userStatus.CHECK_DOCUMENT) {
              this.$toasted.success(this.$i18n.t(`Your documents has been submitted!`))
            }
          }

          const allUploaded = this.checkAllDocuments()

          if (allUploaded) {
            this.disableModify = true
            this.$router.push({ name: 'profile' })
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.your-info {
  position: relative;
  margin-left: 0;
  z-index: 2;

  .title {
    @include font-style($montserrat, 'medium', $font-20);
    color: $dark-gray;
    text-transform: uppercase;
    padding-bottom: 10px;
    border-bottom: .5px solid $dark-gray;
  }
  .your-info-form {
    margin: 50px 0;

    .info {
      .your-info-subtitle {
        @include font-style($montserrat, 'medium', $font-18);
        text-transform: uppercase;
        margin: 30px 0 20px 0;
        color: $dark-blue;
      }
    }
  }
  .complete-profile,
  .documents {
    position: relative;
    background-color: $dark-blue;
    padding-top: 40px;
    padding-bottom: 100px;
    z-index: 2;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      width: 100vw;
      height: 100%;
      background-color: $dark-blue;
      z-index: -1;
    }
    &:after {
      left: 100%;
    }
    &:before {
      left: -100%;
    }

    .documents-title {
      @include font-style($montserrat, 'semibold', $font-18);
      color: $white;
      text-transform: uppercase;
    }
    .upload-row {
      padding: 15px 0;
      border-bottom: .5px solid $gray;

      &.no-border {
        border: none;
      }
      .upload-label {
        @include font-style($montserrat, 'medium', $font-14);
        color: $white;

        .sub-paragraph {
          font-size: $font-12;
        }
      }
      .upload-success {
        color: $success;
      }
      .document-not-uploaded {
        @include font-style($montserrat, 'medium', $font-14);
        color: $warning;
        margin-top: 10px;
      }
      .show-document {
        color: $white;
        margin: 0 10px;
      }
      .remove-document {
        color: $danger;
        margin-right: 10px;
      }
    }
    .contact-us {
      @include font-style($montserrat, 'medium', $font-10);
      text-transform: uppercase;
    }
  }
  .complete-profile {
    @include font-style($montserrat, 'semibold', $font-18);
    color: $white;
    text-transform: uppercase;
    padding: 50px 0;
  }
}

@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
  .your-info {
    .title {
      margin-left: 50px;
    }
    .your-info-form {
      .info {
        margin-left: 50px;
      }
    }
    .complete-profile,
    .documents {
      height: 100%;
      padding: 40px 0 40px 60px;

      &:before {
        display: none;
      }
      &:after {
        z-index: 5;
      }
    }
    .complete-profile {
      padding: 50px 60px;
    }
  }
}
</style>
